export const SEO_SWAP = {
  TITLE: 'Blueshift token swap on Milkomeda',
  DESCRIPTION:
    'Swap ADA, BLUES, Cardano assets, ERC20 tokens and stablecoins on Milkomeda with Blueshift decentralized AMM protocol.',
  KEYWORDS: 'cardano, milkomeda, ada, blues, token, stablecoin, swap, blueshift',
};

export const SEO_PORTFOLIOS = {
  TITLE: 'Blueshift liquidity portfolios on Milkomeda',
  DESCRIPTION:
    'Invest ADA, BLUES, Cardano assets, ERC20 tokens and stablecoins on Milkomeda into Blueshift decentralized portfolios to earn on exchange fees.',
  KEYWORDS: 'cardano, milkomeda, ada, blues, token, stablecoin, blueshift, portfolio, liquidity',
};

export const SEO_PAIRS = {
  TITLE: 'Blueshift liquidity pools on Milkomeda',
  DESCRIPTION:
    'Invest ADA, BLUES, Cardano assets, ERC20 tokens and stablecoins on Milkomeda into Blueshift liquidity pools to earn on exchange fees.',
  KEYWORDS: 'cardano, milkomeda, ada, blues, token, stablecoin, blueshift, pool, liquidity',
};

export const SEO_FARMING = {
  TITLE: 'Blueshift farming on Milkomeda',
  DESCRIPTION:
    'Earn rewards in BLUES tokens for providing ADA, Cardano assets, ERC20 tokens and stablecoins liquidity on Milkomeda with Blueshift farms.',
  KEYWORDS:
    'cardano, milkomeda, ada, blues, token, stablecoin, blueshift, liquidity, farming, farm, apr',
};

export const SEO_STAKING = {
  TITLE: 'Blueshift yield pools on Milkomeda',
  DESCRIPTION:
    'Earn more BLUES tokens by staking your BLUES tokens in Blueshift yield pools on Milkomeda.',
  KEYWORDS: 'cardano, milkomeda, blues, token, blueshift, yield, pool, staking, apr, apy',
};

export const SEO_BRIDGE = {
  TITLE: 'Blueshift token bridge on Milkomeda',
  DESCRIPTION: 'Bridge ADA, BLUES, Cardano assets, ERC20 tokens and stablecoins to/from Milkomeda.',
  KEYWORDS: 'cardano, milkomeda, ada, blues, token, stablecoin, bridge, blueshift',
};

export const PREVIEW_IMAGE = 'https://app.blueshift.fi/blueshift-splash.png';

export const SEO_META_OG = {
  OG_TITLE: SEO_SWAP.TITLE,
  OG_SITE_NAME: SEO_SWAP.TITLE,
  OG_TYPE: 'website',
  OG_DESCRIPTION: SEO_SWAP.DESCRIPTION,
  OG_IMAGE: PREVIEW_IMAGE,
};

export const SEO_META_TWITTER = {
  TWITTER_CARD: 'summary',
  TWITTER_TITLE: SEO_SWAP.TITLE,
  TWITTER_DESCRIPTION: SEO_SWAP.DESCRIPTION,
  PREVIEW_IMAGE: PREVIEW_IMAGE,
};
